export default function () {
    const basicInfoTable = [
        { head: '団体名', body: '大自然界' },
        { head: '区分', body: '文部科学大臣所轄単位宗教法人' },
        { head: '代表者名', body: '濱野祥子' },
        { head: '住所', body: '千葉県勝浦市市野川１３７３番地' },
        { head: '法人成立年', body: '平成元年' },
    ]
    const basicInfoArticle =
        '「大自然界」は、より素晴らしく、より価値ある人生の生き方を行う為の会です。\n \
        \n \
        大自然には、私たち全生物に生命を与えて下さり、この世に誕生させ生かして下さっている、大いなる生命のお力・お働きがあります。大自然の大いなる生命のお力・お働きを、生命の「大親」である「大生命」、『大親神大生命』とお呼び申し上げます。\n \
        \n \
        「大自然界」とは『大親神大生命』の大いなる御心と生命愛（＝親理、大自然の真理法則）を学び実感し、『大親神大生命』が行っていて下さる全ての営みに感謝し、親理に沿って、より良く生きて行く為の会です。\n \
        \n \
        地球人類の全てが『大親神大生命』の御存在と生命愛に目覚めれば、宗教宗派・人種民族や国々で対立・争いなどはせずに、お互いにプラスをし合って、もっと仲良く豊かに平和に暮らす事が出来るはずです。\n \
        \n \
        人類が『大親神大生命』に対し奉り生きる喜びと感謝の念をもたせて頂き、「生命の家族」として助け合い喜び合って生きる、そのような生命愛の素晴らしい地球になる事を目標に、日々活動しております。'

    return { basicInfoTable, basicInfoArticle }
}
