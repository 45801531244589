<template>
    <div id="BasicInfo">
        <section id="basic-info-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">大自然界 基本情報</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="basic-info-title" class="title-section-style hide-mb">
            <h2 class="title f29">大自然界 基本情報</h2>
        </section>
        <section id="basic-info-main" class="w80 w85-mb serif f14 f12-mb">
            <ul class="basic-info-table">
                <li
                    v-for="(item, index) in basicInfoTable"
                    :key="'basic-info-item-' + index"
                >
                    <span class="head">{{ item.head }}</span>
                    <span class="body">{{ item.body }}</span>
                </li>
            </ul>
            <article>
                <p v-html="wrap(basicInfoArticle)"></p>
            </article>
        </section>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getBasicInfoData from '@/data/getBasicInfoData.js'
export default {
    name: 'BasicInfo',
    setup() {
        const { basicInfoTable, basicInfoArticle } = getBasicInfoData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            basicInfoTable,
            basicInfoArticle,
        }
    },
}
</script>
<style lang="scss" scoped>
#basic-info-main {
    ul.basic-info-table {
        span {
            display: inline-block;
        }
        .head {
            width: 7em;
        }
    }
    article {
        border-top: 1px solid $grayE;
    }
}
@media screen and (min-width: 768px) {
    ul.basic-info-table {
        margin-bottom: 50px;
    }
    article {
        padding: 60px 0;
    }
}
@media screen and(max-width:767px) {
    ul,
    article {
        padding: 30px 0;
    }
}
</style>
